/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import "moment/locale/pt-br";
import { Calendar as ReactCalendar } from "react-calendar";
import { Creators as BirthdaysActions } from "store/ducks/birthdays";
import "react-calendar/dist/Calendar.css";
import Flex from "components/core/Grid/Flex";
import {
  smartFormatterDate,
  smartGet,
  startAndEndDateOfTheMonth,
} from "helpers/sharedHelpers";
import { style } from "style";

import { ReactComponent as IconClose } from "assets/img/icons/iconFechar.svg";
import { useCalendarContext } from "context/CalendarContexts";

import {
  StyleCalender,
  IconDay,
  Subtitle,
  EventToday,
  DateEvent,
  SubtitleContainer,
  SideContainerLeft,
  ContainerCalendar,
} from "./CalendarStyle";

const Calendar = ({
  events,
  dateClick,
  handleEventsDay,
  setDateHandleCalender,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [closeInfo, setCloseInfo] = useState(false);
  const { birthdays, bdaysTodayCount, bdaysTodayUsers, dateBdayToday } =
    useSelector((state) => state.birthdays);
  const eventsToday = events?.filter((event) => event.date_event === dateClick);
  const isAppCustom = [
    "Pintou Parceria Suvinil",
    "Vedacit",
    "mestreconstrutor",
    "Ecom"
  ].includes(style.names.nameApp);
  const dispatchGetBirthdays = useCallback(
    () => dispatch(BirthdaysActions.getBirthdays()),
    [dispatch],
  );

  const dispatchGetBdaysTodayUsers = useCallback(
    (dateClick) =>
      dispatch(
        BirthdaysActions.getBirthdaysUsers({ bdayToday: dateClick, page: 1 }),
      ),
    [dispatch],
  );

  const { setSideBarDetails } = useCalendarContext();

  useEffect(() => {
    dispatchGetBirthdays();
  }, [dispatchGetBirthdays]);

  useEffect(() => {
    if (
      !!dateClick &&
      !!dateBdayToday &&
      dateBdayToday === moment(dateClick).format("YYYY-MM-DD")
    ) {
      return;
    }
    !!dateClick && dispatchGetBdaysTodayUsers(dateClick);
  }, [dispatchGetBdaysTodayUsers, dateClick]);

  useEffect(() => {
    handleEventsDay(smartFormatterDate(moment(), "YYYY-MM-DD"));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <StyleCalender>
        <ReactCalendar
          onActiveStartDateChange={(value) => {
            const dataStartAndEndDateOfTheMonth = startAndEndDateOfTheMonth(
              value.activeStartDate,
            );
            setDateHandleCalender(dataStartAndEndDateOfTheMonth);
          }}
          locale="PT-br"
          calendarType="Hebrew"
          onClickDay={(value) => {
            handleEventsDay(smartFormatterDate(value, "YYYY-MM-DD"));
            setCloseInfo(true);
            setSideBarDetails(true);
          }}
          value={new Date()}
          tileContent={({ date, view }) => {
            const day = smartFormatterDate(date, "YYYY-MM-DD");
            const month = smartFormatterDate(date, "MM") - 1;
            return (
              <Flex justifyContent="flex-end" minHeight={26}>
                {view === "month" &&
                  events.some(
                    (event) =>
                      event.date_event === day && event.kind === "holiday",
                  ) && <IconDay kind="holiday" />}

                {view === "month" &&
                  events.some(
                    (event) =>
                      event.date_event === day && event.kind === "common",
                  ) && <IconDay kind="common" />}

                {view === "month" &&
                  birthdays[month]?.members?.filter(
                    (member) =>
                      smartGet(day, "date") ===
                      smartGet(member?.datebirthday, "date"),
                  ).length > 0 &&
                  !isAppCustom && <IconDay kind="birthdate" />}
              </Flex>
            );
          }}
        />
      </StyleCalender>
      <Flex mt={28}>
        <SubtitleContainer>
          <Subtitle>{intl.messages["calendar.textPage.subtitle"]}:</Subtitle>
          <IconDay kind="common" subtitle />
          <Subtitle black>{intl.messages["calendar.textPage.events"]}</Subtitle>
          {!isAppCustom && (
            <>
              <IconDay kind="birthdate" subtitle />
              <Subtitle black>{intl.messages["menu.birthdays"]}</Subtitle>
            </>
          )}
          <IconDay kind="holiday" subtitle />
          <Subtitle black>
            {intl.messages["calendar.textPage.holidays"]}
          </Subtitle>
        </SubtitleContainer>
      </Flex>

      {closeInfo &&
        (eventsToday.length > 0 ||
        (bdaysTodayCount !== undefined && bdaysTodayCount > 0) ? (
          <ContainerCalendar>
            <Flex justifyContent="space-between">
              <div>
                <DateEvent>
                  {moment.weekdays(smartGet(dateClick, "day"))}
                </DateEvent>
                <h3>
                  {smartGet(dateClick, "date")}
                  {" de "}
                  {moment.months(smartGet(dateClick, "month"))}
                </h3>
              </div>
              <IconClose onClick={() => setCloseInfo(false)} />
            </Flex>

            <EventToday>
              {eventsToday.length > 0 &&
                eventsToday.map(({ id, kind, title }) => (
                  <SideContainerLeft key={id}>
                    <DateEvent>
                      {kind === "holiday"
                        ? `${intl.messages["calendar.textPage.holiday"]}`
                        : `${intl.messages["calendar.textPage.event"]}`}
                    </DateEvent>
                    <h3>{title}</h3>
                  </SideContainerLeft>
                ))}

              {bdaysTodayCount !== undefined &&
                bdaysTodayCount > 0 &&
                bdaysTodayUsers.map(({ id, name }) => (
                  <SideContainerLeft key={id}>
                    <DateEvent>{intl.messages["menu.birthdays"]}</DateEvent>
                    <h3>{name}</h3>
                  </SideContainerLeft>
                ))}
            </EventToday>
          </ContainerCalendar>
        ) : (
          <ContainerCalendar>
            <Flex justifyContent="space-between">
              <div>
                <DateEvent>
                  {moment.weekdays(smartGet(dateClick, "day"))}
                </DateEvent>
                <h3>
                  {smartGet(dateClick, "date")}
                  {" de "}
                  {moment.months(smartGet(dateClick, "month"))}
                </h3>
              </div>
              <IconClose onClick={() => setCloseInfo(false)} />
            </Flex>
            <EventToday>
              <h2>{intl.messages["calendar.textPage.noEventsForThatDay"]}</h2>
            </EventToday>
          </ContainerCalendar>
        ))}
    </>
  );
};
export default Calendar;
